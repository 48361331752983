import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription,
    fetchDiscountSubscription,
    setCancellationCandidate,
    updateSubscription,
} from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { COUNTRIES_WITHOUT_DISCOUNT_OFFER } from 'constants/countries';

import {
    sendAnalyticCancelSubscriptionClick,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

const useCancelSubscriptionFlow = () => {
    const { t } = useTranslation();
    const { cancellationCandidate, discountSubscription } = useSelector(selectSubscriptions);
    const { country } = useSelector(selectCurrentUser);

    const dispatch = useDispatch();

    const { openModal, closeModal } = useModals();

    const initSubscriptionCancellation = useCallback(
        (candidate: ISubscription) => {
            const { product_code, external_id, discount_type } = candidate;
            const isCancellationCandidateMain = isMainSubscription(product_code);
            const isDiscountAllowed = country ? !COUNTRIES_WITHOUT_DISCOUNT_OFFER.includes(country) : false;

            if (isCancellationCandidateMain && !discount_type) {
                if (isDiscountAllowed) {
                    dispatch(fetchDiscountSubscription({ discount_type: DISCOUNT_TYPES.base, external_id }));
                }

                openModal(ModalName.CancellationReasonModal);
            } else {
                openModal(ModalName.ConfirmationModal);
            }

            dispatch(setCancellationCandidate(candidate));
            sendAnalyticCancelSubscriptionClick(external_id);
        },
        [dispatch, openModal, country]
    );

    const discardCancellation = useCallback(() => {
        dispatch(setCancellationCandidate(null));
    }, [dispatch]);

    const onCancelSubscription = useCallback(() => {
        if (!cancellationCandidate) return;

        const { external_id: externalId } = cancellationCandidate;

        openModal(ModalName.WaitingModal);

        dispatch(
            cancelSubscription({
                externalId,
                onError: () => {
                    sendAnalyticSubscriptionCancelError(externalId);
                },
                onSuccess: () => {
                    discardCancellation();
                    sendAnalyticSubscriptionCancelSuccess(externalId);
                },
                onCancel: () => {
                    discardCancellation();
                    closeModal();
                },
            })
        );
    }, [cancellationCandidate, discardCancellation, closeModal, openModal, dispatch]);

    const onChangeSubscription = useCallback(() => {
        if (!discountSubscription || !cancellationCandidate) return;

        openModal(ModalName.WithLoaderModal, { title: t('subscription.discountOfferModal.updatingPlan') });
        dispatch(
            updateSubscription({
                product: discountSubscription,
                external_id: cancellationCandidate.external_id,
                discount_type: DISCOUNT_TYPES.base,
            })
        );
    }, [discountSubscription, dispatch, openModal, t, cancellationCandidate]);

    return {
        initSubscriptionCancellation,
        cancellationCandidate,
        onCancelSubscription,
        discardCancellation,
        onChangeSubscription,
        discountSubscription,
    };
};

export default useCancelSubscriptionFlow;
