import { ActionEventAction, ElementEventAction, EventTrigger, EventTriggerFixed, Main } from 'wikr-core-analytics';

import {
    DISCARD_CANCEL_OFFER_SCREEN_ID,
    DISCOUNT_OFFER_SCREEN_ID,
    KEEP_SUBSCRIPTION_EVENT,
    SUBSCRIPTION_SCREEN_ID,
} from 'constants/analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticCancelSubscriptionClick = (eventLabel: string) => {
    trackClick({
        screenIdParam: SUBSCRIPTION_SCREEN_ID,
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.cancelSubscription,
        eventLabel,
    });
};
export const sendAnalyticContinueSubscriptionCancelClick = (eventLabel: {
    reason: string;
    subscription_id: string;
}) => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: SUBSCRIPTION_SCREEN_ID },
        data: { event_label: eventLabel },

        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionData: { elementEvent: ElementEventAction.button, actionEvent: 'continue_cancel' },
    });
};

export const sendAnalyticSubscriptionCancelError = (eventLabel: string) => {
    // @todo: remove after lib will contain those events
    // @ts-ignore
    trackEventFixed('subscription_cancel_error', eventLabel);
};

export const sendAnalyticFrontCancelSubscriptionRequest = (eventLabel?: string) => {
    // @todo: remove after lib will contain those events
    // @ts-ignore
    trackEventFixed('front_cancel_subscription_request', eventLabel);
};

export const sendAnalyticSubscriptionCancelSuccess = (eventLabel: string) => {
    trackEventFixed(EventTriggerFixed.subscriptionCancelSuccess, eventLabel);
};

export const sendAnalyticCancelReasonContinueClick = (eventLabel: { reason: string; subscription_id: string }) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.quizContinue,
        eventLabel,
    });
};

export const sendAnalyticChangeSubscriptionClick = (event_label: string) => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: DISCOUNT_OFFER_SCREEN_ID },
        data: { event_label },
        actionData: { elementEvent: ElementEventAction.button, actionEvent: ActionEventAction.continue },
    });
};

export const sendAnalyticCloseOfferClick = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: DISCOUNT_OFFER_SCREEN_ID },
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionData: { elementEvent: ElementEventAction.button, actionEvent: 'continue_cancel' },
    });
};

export const sendAnalyticDiscardCancelOfferClick = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: DISCARD_CANCEL_OFFER_SCREEN_ID },
        actionData: { elementEvent: ElementEventAction.button, actionEvent: ActionEventAction.back },
    });
};

export const sendAnalyticDiscountAppliedResult = (event: string, eventLabel: string) => {
    // @todo: remove after lib will contain those event triggers
    // @ts-ignore
    trackEventFixed(event, eventLabel);
};

export const sendAnalyticKeepSubscriptionClick = () => {
    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId: KEEP_SUBSCRIPTION_EVENT },
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionData: { elementEvent: ElementEventAction.button, actionEvent: 'continue_keep' },
    });
};

export const sendAnalyticRestoreButtonClick = (
    eventType: string,
    eventLabel: object,
    actionEvent: string = EventTrigger.click
) => {
    trackClick({
        screenIdParam: eventType,
        // @ts-ignore
        elementEvent: ElementEventAction.button,
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionEvent,
        eventLabel,
    });
};

export const sendAnalyticChangePaymentMethodButtonClick = (
    eventType: string,
    eventLabel: object,
    actionEvent: string = EventTrigger.click
) => {
    trackClick({
        screenIdParam: eventType,
        // @ts-ignore
        elementEvent: ElementEventAction.button,
        // @todo: remove after lib will contain those eventAction
        // @ts-ignore
        actionEvent,
        eventLabel,
    });
};
