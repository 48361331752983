import * as action from './actions';

import * as actionTypes from './actionTypes';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

export interface SubscriptionsState {
    subscriptions: ISubscription[];
    isLoading: boolean;
    cancellationCandidate: ISubscription | null;
    discountSubscription: IDiscountSubscription | null;
}

const initialState = {
    subscriptions: [],
    isLoading: true,
    cancellationCandidate: null,
    discountSubscription: null,
};

const subscriptionsReducer = (state: SubscriptionsState = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.RESET_SUBSCRIPTIONS:
            return { ...initialState };

        case actionTypes.SET_FETCHING_STATUS:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            return { ...state, isLoading: false, subscriptions: action.payload };

        case actionTypes.SET_CANCELLATION_CANDIDATE:
            return { ...state, cancellationCandidate: action.payload };

        case actionTypes.SET_DISCOUNT_SUBSCRIPTION:
            return { ...state, discountSubscription: action.payload };

        default:
            return state;
    }
};

export default subscriptionsReducer;
