import { Provider } from 'wikr-core-analytics';
import { call, takeLatest, all, put } from 'redux-saga/effects';

import { removeToken, setToken } from 'api/utils/tokenManagement';
import BrowserStorage from 'api/utils/browserStorage';
import api from 'api';

import { getCurrentUserSuccess, resetUserData } from 'store/user/actions';
import { resetSubscriptions } from 'store/subscriptions/actions';
import { notifyError } from 'store/notifications/actions';

import {
    signInRequest,
    signInSuccess,
    signInError,
    authentication,
    setAuthenticationStatus,
    authenticationBySignature,
    setAuthRedirectUrl,
} from './actions';

import { SIGN_IN_REQUEST, AUTHENTICATE, AUTHENTICATE_BY_SIGNATURE, LOG_OUT } from './actionTypes';

import { getParamFromUrl } from 'helpers/utils';
import { userAPIResponseTransformer } from 'helpers/api';

import { UserStore } from 'types/store/userStore';

function* signIn({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onSuccess, onError } = payload;

    try {
        const userDataResponse: UserStore = yield call(api.user.signIn, userData);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userDataResponse)));
        yield put(setAuthenticationStatus(true));
        yield put(signInSuccess(userDataResponse));

        const getCountry = async () => {
            return new Promise((resolve) => {
                resolve(userDataResponse.country);
            });
        };

        const getAbTestName = () => {
            const abTestName = null;

            return new Promise((resolve) => {
                resolve(abTestName);
            });
        };

        Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => await getAbTestName(),
        });
        Provider.setUserId(userDataResponse.user_id);

        onSuccess && onSuccess(userDataResponse);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));

        if (error?.message) {
            yield put(signInError(error.message));

            if (onError) {
                onError(error.message);
            }
        }
    }
}

function* authenticate({ payload }: ReturnType<typeof authentication>) {
    const { onSuccess, onError } = payload;

    try {
        const userData: UserStore = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        yield put(setAuthenticationStatus(true));

        Provider.setUserId(userData.user_id);

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError(error);
    }
}

function* authenticateBySignature({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature, onSuccess, onError } = payload;
    const utmSource = getParamFromUrl('utm_source') || 'default_device';

    localStorage.setItem('utmSource', utmSource);
    Provider.setUTMSource(utmSource);

    try {
        const userData: UserStore = yield call(api.user.getUserBySignature, { uId, signature });

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        yield put(setAuthenticationStatus(true));

        Provider.setUserId(userData.user_id);
        userData?.token && setToken(userData.token);

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* logout() {
    removeToken();
    BrowserStorage.removeItem('isAppDownloaded');

    yield put(setAuthenticationStatus(false));
    yield put(resetUserData());
    yield put(resetSubscriptions());
}

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logout),
        takeLatest(SIGN_IN_REQUEST, signIn),
        takeLatest(AUTHENTICATE, authenticate),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignature),
    ]);
}
