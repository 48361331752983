import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { sendFeedback } from 'store/subscriptions/actions';

import { COUNTRIES_WITHOUT_DISCOUNT_OFFER } from 'constants/countries';
import { PRESELECTED_REASON } from 'components/Modals/Subscriptions/CancellationReasonModal/constants';

import { sendAnalyticCancelReasonContinueClick, sendAnalyticContinueSubscriptionCancelClick } from 'services/analytics';

import { useModals } from 'hooks/modals';
import { useDispatch } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';
import { IUserFeedback } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import useCancelSubscription from './useCancelSubscriptionFlow';

const useCancelSubscriptionModals = () => {
    const dispatch = useDispatch();

    const { openModal } = useModals();
    const { cancellationCandidate, onCancelSubscription } = useCancelSubscription();
    const { country } = useSelector(selectCurrentUser);

    const onSubmitSurvey = useCallback(
        ({ reason, feedback }: IUserFeedback) => {
            if (!cancellationCandidate) return;

            const { product_code, external_id, discount_type } = cancellationCandidate;

            const isCancellationCandidateIsMain = isMainSubscription(product_code);
            const isDiscountAllowed = country ? !COUNTRIES_WITHOUT_DISCOUNT_OFFER.includes(country) : false;

            const isDiscountAvailable =
                isCancellationCandidateIsMain &&
                reason.shortKey === PRESELECTED_REASON.shortKey &&
                !discount_type &&
                isDiscountAllowed;

            sendAnalyticContinueSubscriptionCancelClick({
                reason: reason.shortKey,
                subscription_id: external_id,
            });

            sendAnalyticCancelReasonContinueClick({
                reason: reason.shortKey,
                subscription_id: external_id,
            });

            dispatch(
                sendFeedback({
                    comment: feedback,
                    reason: reason.shortKey,
                })
            );

            if (isDiscountAvailable) {
                openModal(ModalName.DiscountModal);
            } else {
                onCancelSubscription();
            }
        },
        [cancellationCandidate, onCancelSubscription, dispatch, openModal, country]
    );

    return {
        onSubmitSurvey,
    };
};

export default useCancelSubscriptionModals;
