import { Button, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './styled';

import { SubscriptionCardPropsType } from './types';

const SubscriptionCard = ({
    title,
    bgColor,
    period,
    price,
    currencySign,
    isDiscountCard,
    oldPrice,
    changeSubscription,
}: SubscriptionCardPropsType) => {
    const { t } = useTranslation();

    return (
        <S.SubscriptionCard>
            <S.SubscriptionCardTitle
                dataLocator={isDiscountCard ? 'unsubscribeDiscount' : undefined}
                medium
                center
                color="surface-main"
                text={title}
                bgColor={bgColor}
            />
            <S.SubscriptionCardContent
                data-locator={isDiscountCard ? 'unsubscribeNewPlanBlock' : 'unsubscribeCurrentPlanBlock'}
            >
                <Text
                    type="small-button"
                    center
                    mb={20}
                    text={t('subscription.discountOfferModal.discountCard.period', {
                        period,
                    })}
                    dataLocator="unsubscribeCurrentPlan"
                />
                {isDiscountCard && (
                    <S.SubscriptionCardOldPrice
                        dataLocator="unsubscribeOldPrice"
                        type="large-text"
                        center
                        mb={8}
                        text={t('subscription.discountOfferModal.discountCard.oldPrice', {
                            currencySign,
                            price: oldPrice,
                            period: period,
                        })}
                        medium
                    />
                )}
                <Text
                    dataLocator={isDiscountCard ? 'unsubscribeNewPlanPrice' : 'unsubscribeCurrentPlanPrice'}
                    type="h2"
                    medium
                    center
                    mb={isDiscountCard ? 20 : 0}
                >
                    <Trans
                        i18nKey="subscription.discountOfferModal.discountCard.pricePeriod"
                        values={{
                            currencySign,
                            price,
                            period: period,
                        }}
                        components={{
                            span: <S.SubscriptionCardPricePeriod />,
                        }}
                    />
                </Text>
                {isDiscountCard && (
                    <>
                        <Text
                            center
                            mb={20}
                            type="caption"
                            text={t('subscription.discountOfferModal.discountCard.description')}
                        />
                        <Button
                            backgroundColor="primary-default"
                            text={t('subscription.discountOfferModal.btn.changePlan')}
                            onClick={changeSubscription}
                            dataLocator="unsubscribeChangePlanButton"
                        />
                    </>
                )}
            </S.SubscriptionCardContent>
        </S.SubscriptionCard>
    );
};

export default SubscriptionCard;
