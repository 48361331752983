import ApiClient from 'services/ApiClient';

import {
    DeepLinkData,
    SignInData,
    UserInfoResponse,
    UserUpdateData,
    ChangePasswordType,
    HashedEmailResp,
} from 'types/user/userApiInterface';

class UserApi {
    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/');
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>('user/', payload);
    };

    changePassword = async <Data = ChangePasswordType, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/password-change', payload);
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-in', payload);
    };

    resetPassword = async <Data = SignInData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/reset-password', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/auth-deeplink');
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    };

    getProducts = async () => {
        return await ApiClient.get<HashedEmailResp>('user/products');
    };
}

export default UserApi;
