import { ActionEventAction } from 'wikr-core-analytics';

import config from 'appConfig';

import routes from 'router/routes';

import { EVENT_ACTION_LOCAL } from 'constants/eventAction';

import { getLocalizationFromAvailable } from 'helpers/localization';
import isMobile from 'helpers/isMobile';
import { getFaqLinks, getPersonalPlanLinks } from 'helpers/getMenuItemsLinks';

export const PRIMARY_SIDEBAR_LINKS = [
    {
        to: routes.PRIVATE.MAIN.path,
        title: 'sidebar.app',
        dataLocator: 'burgerYogaGoApp',
        eventAction: ActionEventAction.app,
    },
    {
        to: routes.PRIVATE.FITNESS_GUIDES.path,
        title: 'pagesTitles.guides',
        dataLocator: 'burgerGuides',
        eventAction: ActionEventAction.guides,
    },

    {
        to: getPersonalPlanLinks(getLocalizationFromAvailable()),
        title: 'account.tabsTitles.personalizedPlan',
        dataLocator: 'burgerPersonalizedPlan',
        eventAction: EVENT_ACTION_LOCAL.PERSONAL_PLAN,
        target: isMobile.any() ? '_blank' : '_self',
        rel: isMobile.any() ? 'noopener noreferrer' : '',
    },

    {
        to: routes.PRIVATE.ACCOUNT_DEFAULT.path,
        title: 'account.tabsTitles.settings',
        dataLocator: 'burgerAccountSettings',
        eventAction: ActionEventAction.accountSettings,
    },
];

export const SECONDARY_SIDEBAR_LINKS = [
    {
        to: getFaqLinks(`${getLocalizationFromAvailable()}`),
        title: 'sidebar.faq',
        dataLocator: 'burgerFAQ',
        target: '_blank',
        rel: 'noopener noreferrer',
        eventAction: ActionEventAction.faq,
    },
    {
        to: `${config.links.termsOfUse}?language=${getLocalizationFromAvailable()}`,
        title: 'sidebar.terms',
        dataLocator: 'burgerTermsOfUse',
        target: '_blank',
        rel: 'noopener noreferrer',
        eventAction: ActionEventAction.termsOfUse,
    },
    {
        to: `${config.links.privacyPolicy}?language=${getLocalizationFromAvailable()}`,
        title: 'sidebar.privacy',
        dataLocator: 'burgerPrivacyPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        eventAction: ActionEventAction.privacyPolicy,
    },
    {
        to: `${config.links.refundPolicy}?language=${getLocalizationFromAvailable()}`,
        title: 'sidebar.refund',
        dataLocator: 'burgerRefundPolicy',
        target: '_blank',
        rel: 'noopener noreferrer',
        eventAction: ActionEventAction.refundPolicy,
    },
];

export const SIDEBAR_WIDTH = 260;
