export enum AccountTab {
    Profile = 'profile',
    Subscription = 'subscription',
    Settings = 'settings',
    PlanSettings = 'plan-settings',
}

export const TABS = [
    { id: AccountTab.Profile, title: 'account.tabsTitles.profile', dataLocator: 'profileSettingsTab' },
    { id: AccountTab.PlanSettings, title: 'account.tabsTitles.planSettings', dataLocator: 'planSettingsTab' },
    { id: AccountTab.Subscription, title: 'account.tabsTitles.subscription', dataLocator: 'subscriptionSettingsTab' },
    { id: AccountTab.Settings, title: 'account.tabsTitles.settings', dataLocator: 'accountSettingsTab' },
];
