/* eslint-disable max-lines */
import { delay, put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { CancelledEffect } from '@redux-saga/core/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { openModal } from 'store/modals/actions';

import {
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    cancelSubscription,
    fetchSubscriptions,
    sendFeedback,
    updateSubscription,
    fetchDiscountSubscription,
    setDiscountSubscription,
    restoreSubscription,
} from './actions';
import { notifyError, notifySuccess } from '../notifications/actions';

import {
    CANCEL_SUBSCRIPTION,
    DISCARD_SUBSCRIPTION_CANCELLATION,
    FETCH_USER_SUBSCRIPTIONS,
    SEND_SUBSCRIPTION_FEEDBACK,
    UPDATE_SUBSCRIPTION,
    FETCH_DISCOUNT_SUBSCRIPTION,
    RESTORE_SUBSCRIPTION,
} from './actionTypes';

import { DISCOUNT_APPLIED_ERROR, DISCOUNT_APPLIED_SUCCESS } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticDiscountAppliedResult, sendAnalyticFrontCancelSubscriptionRequest } from 'services/analytics';

import {
    getCancelSubscriptionSuccessModalData,
    getChangeSubscriptionSuccessModalData,
    getErrorModalData,
    getResoreSuccessModalData,
} from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ISubscription, ICancelSubscription, IDiscountSubscription } from 'types/subscription';

function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(response));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);

        yield take(DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    try {
        yield call(api.subscriptions.sendFeedback, payload);
    } catch (error) {
        console.error(error);
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    try {
        yield delay(3000);

        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        yield put(fetchSubscriptions());
        yield put(openModal(ModalName.SuccessModal, getCancelSubscriptionSuccessModalData()));

        payload.onSuccess();
    } catch (error) {
        yield put(
            openModal(ModalName.CancelSubscriptionErrorModal, getErrorModalData('subscription.cancel.error.title'))
        );
        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notifySuccess('subscription.cancellation.response.abort'));
            payload.onCancel();
        }
    }
}

function* changeCurrentSubscription({ payload }: ReturnType<typeof updateSubscription>) {
    try {
        yield api.subscriptions.updateSubscription(payload);

        yield put(fetchSubscriptions());
        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(payload.product)));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, payload.product.name);
    } catch (error) {
        yield put(
            openModal(
                ModalName.ChangeSubscriptionErrorModal,
                getErrorModalData('subscription.changeSubscription.error.title')
            )
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscription>) {
    try {
        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscription, payload);

        yield delay(3000);
        yield put(setDiscountSubscription(response));
    } catch (error) {
        console.error(error);
    }
}

function* requestRestoreSubscription({ payload }: ReturnType<typeof restoreSubscription>) {
    const { external_id, product } = payload;

    try {
        yield call(api.subscriptions.restoreSubscription, { external_id });

        yield put(openModal(ModalName.SuccessModal, getResoreSuccessModalData(product)));

        yield call(trackScreenLoad, 'restore_success', {
            subscription_id: external_id,
            period: product.subscription_period,
            tariff: product.name,
            content_id: product.id,
        });

        yield put(fetchSubscriptions());
    } catch (error) {
        yield put(openModal(ModalName.RestoreSubscriptionErrorModal, payload));

        console.error(error);
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
        takeLatest(UPDATE_SUBSCRIPTION, changeCurrentSubscription),
        takeLatest(FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(RESTORE_SUBSCRIPTION, requestRestoreSubscription),
    ]);
}
