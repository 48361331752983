import { Text } from 'wikr-core-components';
import { ActionEventAction } from 'wikr-core-analytics';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { logout } from 'store/auth/actions';

import { EVENT_ACTION_LOCAL } from 'constants/eventAction';
import { PRIMARY_SIDEBAR_LINKS, SECONDARY_SIDEBAR_LINKS } from './constants';

import { sendAnalyticSideBarElementClick } from 'services/analytics';

import { useIsPaidUser } from 'hooks/user';
import { useDispatch, useSidebar } from 'hooks';

import { TagBeta } from 'components/TagBeta';
import SidebarLink from './components/SidebarLink';

import * as S from './styled';

import { linkType } from './types';

export default function SideBar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isOpen, close } = useSidebar();

    const navigate = useNavigate();

    const isFeatureEnable = useIsPaidUser();

    const handleLogout = () => {
        sendAnalyticSideBarElementClick(ActionEventAction.logOut);
        dispatch(logout());
        navigate('/');
        close();
    };

    const elementClickHandler = (eventAction?: ActionEventAction) => {
        eventAction && sendAnalyticSideBarElementClick(eventAction);
        close();
    };

    return (
        <S.SideBarWrapper isOpen={isOpen}>
            <S.SideBarOverlay onClick={close} />
            <S.SideBarMenu>
                <S.PrimaryLinksBox paddingTop={32} paddingX={8}>
                    {PRIMARY_SIDEBAR_LINKS.map(({ eventAction, to, title, dataLocator, ...rest }) => {
                        const isPersonalPlan =
                            config?.IS_PERSONAL_PLAN && Boolean(JSON.parse(config?.IS_PERSONAL_PLAN));

                        const isPersonaPlanMenuItem = eventAction === EVENT_ACTION_LOCAL.PERSONAL_PLAN;

                        if (isPersonaPlanMenuItem) {
                            // double return needed because value from vault config must have the highest priority and override all other conditions
                            // e.g. if (!isPersonalPlan || !isFeatureEnable) return; won't work because isFeatureEnable can return true while value from vault is false
                            if (!isPersonalPlan) return;
                            if (!isFeatureEnable) return;
                        }

                        return (
                            <SidebarLink
                                key={dataLocator}
                                // @ts-ignore
                                onClick={() => elementClickHandler(eventAction)}
                                to={to}
                                title={title}
                                dataLocator={dataLocator}
                                type={linkType.Primary}
                                itemLabel={isPersonaPlanMenuItem ? TagBeta : undefined}
                                {...rest}
                            />
                        );
                    })}
                    <S.Hr />
                </S.PrimaryLinksBox>

                <S.PrimaryLinksBox paddingTop={32} paddingX={8}>
                    {SECONDARY_SIDEBAR_LINKS.map(({ eventAction, dataLocator, title, to, ...rest }) => (
                        <S.LinkSecondaryButton
                            data-locator={dataLocator}
                            key={dataLocator}
                            href={to}
                            onClick={() => elementClickHandler(eventAction)}
                            {...rest}
                        >
                            <Text text={t(title)} />
                        </S.LinkSecondaryButton>
                    ))}

                    <S.NavSecondaryButton onClick={handleLogout} data-locator="burgerLogoutBtn">
                        <Text text={t('sidebar.logout')} />
                    </S.NavSecondaryButton>
                    <S.Hr />
                </S.PrimaryLinksBox>
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}
