import { IWorkoutBlock, IWorkoutExercisesBlock } from 'types/personalPlan';
import { ReactNode } from 'react';

export type ExercisePlayerPropsTypes = WorkoutPaginationTypes & {
    currentExercise: IWorkoutExercisesBlock;
    toNextExercise: () => void;
    toPrevExercise: () => void;
    onProgress: (id: number, progressData: ProgressDataType) => void;
    fancyProgressTime: ReactNode;
    handleCompleteWorkout: () => void;
    nextExerciseData: IWorkoutExercisesBlock;
    prevExerciseData: IWorkoutExercisesBlock;
    isNavigationLoading?: boolean;
    totalProgress: number;
};

export type ProgressDataType = {
    time: number;
    percents: number;
};

export type ProgressDataListType = {
    [key: string]: ProgressDataType;
};

export type WorkoutPaginationTypes = {
    workoutExercises: IWorkoutExercisesBlock[];
    currentIndex: number;
    progress: {
        [key: string]: {
            percents: number;
        };
    };
};

export type WorkoutNavigationBarTypes = {
    prevExercise: IWorkoutExercisesBlock;
    nextExerciseData: IWorkoutExercisesBlock;
    toPrevExercise: () => void;
    toNextExercise: () => void;
    isLoading?: boolean;
};

export type ExercisePlayerHeaderType = WorkoutPaginationTypes & {
    handlePlayButton: (isPlay: boolean) => void;
    handleCompleteWorkout: () => void;
    fancyProgressTime: ReactNode;
    totalProgress: number;
};

export type ExerciseItemType = {
    name: string;
    preview: string;
    time?: number;
    onClick?: () => void;
    isActive?: boolean;
};

export type ExerciseListType = {
    exercises: IWorkoutExercisesBlock[];
    onClick?: (exercise: IWorkoutExercisesBlock) => void;
    activeExercise?: number;
};

export type WorkoutExercisesListTypes = {
    blocks: IWorkoutBlock[];
    onClick?: (exercise: IWorkoutExercisesBlock) => void;
    activeExerciseId?: number;
    exercises?: IWorkoutExercisesBlock[];
};

export enum WorkoutState {
    completedBeforeToday = 'completedBeforeToday',
    availableToday = 'availableToday',
    completedToday = 'completedToday',
    notAvailableToday = 'notAvailableToday',
}
