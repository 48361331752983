import { StyleSheetManager } from 'styled-components';
import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';

import { useNetworkStatus } from 'hooks/common';

import { setParamsToWindow } from 'helpers/window';
import { isProd } from 'helpers/utils';
import { shouldForwardProp } from 'helpers/styledComponents';
import { addQACommandsToWindow } from 'helpers/qaCommands';

import NotificationsContainer from 'containers/Notifications';

import ThemeProvider from './theme/provider';
import router from './router';

function App() {
    if (!isProd()) addQACommandsToWindow();

    useNetworkStatus();

    setParamsToWindow();

    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider>
                <NotificationsContainer />
                <Suspense fallback={null}>
                    <RouterProvider router={router} />
                </Suspense>
            </ThemeProvider>
        </StyleSheetManager>
    );
}

export default App;
