import { useTranslation } from 'react-i18next';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import { CURRENCY_SIGN_MAP } from 'constants/currency';

import { useSelector } from 'hooks';

import { getSubscriptionData } from 'helpers/subscriptions';

const useDiscountModal = () => {
    const { t } = useTranslation();

    const { cancellationCandidate, discountSubscription } = useSelector(selectSubscriptions);

    if (!cancellationCandidate || !discountSubscription) return {};

    const { product } = cancellationCandidate;

    return {
        currentSubscription: getSubscriptionData(t, product),
        discountOffer: getSubscriptionData(t, discountSubscription),
        currencySign: CURRENCY_SIGN_MAP[product.currency],
    };
};

export default useDiscountModal;
