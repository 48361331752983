import { Box, Colors, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const SubscriptionCard = styled(Box)`
    background-color: ${color('surface-main')};
    border-radius: 20px;
`;
export const SubscriptionCardTitle = styled(Text)<{ bgColor: Colors }>`
    padding-left: 20px;
    padding-right: 20px;
    background-color: ${({ bgColor }) => color(bgColor)};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
`;

export const SubscriptionCardContent = styled.div`
    padding: 20px 16px 16px;
`;

export const SubscriptionCardOldPrice = styled(Text)`
    text-decoration: line-through;
`;

export const SubscriptionCardPricePeriod = styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
`;
