export const COMPLEXITY_LEVELS = {
    beginner: 'beginner',
    intermediate: 'intermediate',
    advanced: 'advanced',
};

export const COMPLEXITY_LEVELS_ARR = [
    COMPLEXITY_LEVELS.beginner,
    COMPLEXITY_LEVELS.intermediate,
    COMPLEXITY_LEVELS.advanced,
];
