import { Text, Button, Box } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useLayoutEffect } from 'react';
import { useFormik } from 'formik';

import { OTHER_REASON, PRESELECTED_REASON, reasons } from './constants';

import { sendAnalyticKeepSubscriptionClick, sendAnalyticTakeQuizClick } from 'services/analytics';

import useCancelSubscriptionModals from 'hooks/subscriptions/useCancelSubscriptionModals';
import { useCancelSubscriptionFlow, useModals } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';
import isMobile from 'helpers/isMobile';

import Textarea from 'components/Textarea';
import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal';

import * as S from './styled';

import { ISubscription } from 'types/subscription';
import { IReason } from './types';

import getValidationSchema from './validation-scheme';

const CancellationReasonModal = () => {
    const { t } = useTranslation();
    const { onSubmitSurvey } = useCancelSubscriptionModals();
    const { discardCancellation, cancellationCandidate } = useCancelSubscriptionFlow();
    const { closeModal } = useModals();

    const formRef = useRef<null | HTMLDivElement>(null);

    const preselectedReason =
        !cancellationCandidate?.discount_type &&
        isMainSubscription(cancellationCandidate?.product_code as ISubscription['product_code'])
            ? PRESELECTED_REASON
            : null;

    const [selectedReason, setReason] = useState<IReason | null>(preselectedReason);

    const handleDiscard = () => {
        sendAnalyticKeepSubscriptionClick();
        discardCancellation();
        closeModal();
    };

    const submitSurvey = ({ feedback }: { feedback: string }) => {
        if (!selectedReason) return;

        onSubmitSurvey({
            reason: selectedReason,
            feedback,
        });
    };

    const { values, handleChange, handleSubmit, errors, handleBlur, resetForm } = useFormik({
        initialValues: { feedback: '' },
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: getValidationSchema(t),
        onSubmit: submitSurvey,
    });

    const handleSelectReason = (reason: IReason) => () => {
        setReason(reason);
        sendAnalyticTakeQuizClick();
    };

    useLayoutEffect(() => {
        if (!formRef.current) return;

        formRef.current.scrollIntoView({ behavior: 'smooth' });

        resetForm();
    }, [formRef, selectedReason, resetForm]);

    return (
        <Modal isOpen fullscreen={isMobile.any()} dataLocator="reasonModal">
            <Box paddingTop={20} paddingBottom={20}>
                <Text type="h5" color="text-main" center mb={24} text={t('subscription.cancellationReason.title')} />

                <Box mb={32}>
                    {reasons.map((reason) => {
                        const { title, shortKey } = reason;

                        return (
                            <S.ReasonItem
                                key={shortKey}
                                onClick={handleSelectReason(reason)}
                                isSelected={shortKey === selectedReason?.shortKey}
                                data-locator="option"
                            >
                                <Text type="medium-text" bold text={t(title)} />
                            </S.ReasonItem>
                        );
                    })}
                </Box>

                <form onSubmit={handleSubmit}>
                    {selectedReason?.shortKey === OTHER_REASON && (
                        <div ref={formRef}>
                            <Box paddingTop={20} paddingBottom={40}>
                                <Text type="h5" mb={12} text={t('subscription.cancellationReason.other.question')} />
                                <Text
                                    type="caption"
                                    color="text-secondary-subdued"
                                    mb={24}
                                    text={t('subscription.cancellation.feedback.rule')}
                                />
                                <Textarea
                                    name="feedback"
                                    autocomplete="off"
                                    rows="6"
                                    placeholder={t('subscription.cancellation.feedback.placeholder')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.feedback}
                                    invalid={Boolean(errors.feedback)}
                                />
                                {errors.feedback && (
                                    <Text type="caption" color="critical-default" mb={24} text={t(errors.feedback)} />
                                )}
                            </Box>
                        </div>
                    )}
                    <Button
                        dataLocator="continue"
                        disabled={Boolean(errors.feedback || !selectedReason)}
                        mb={20}
                        text={t('basics.continue')}
                    />
                </form>

                <OverlineButton
                    dataLocator="cancel"
                    text={t('subscription.cancelDialog.discard')}
                    onClick={handleDiscard}
                />
            </Box>
        </Modal>
    );
};

export default CancellationReasonModal;
