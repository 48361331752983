import * as actionTypes from './actionTypes';

import {
    ISubscription,
    ICancelSubscription,
    IFetchDiscountSubscription,
    IDiscountSubscription,
    IUpdateSubscriptionData,
} from 'types/subscription';

export const fetchSubscriptions = () => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const discardSubscriptionCancellation = () => {
    return {
        type: actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const resetSubscriptions = () => {
    return {
        type: actionTypes.RESET_SUBSCRIPTIONS,
    } as const;
};

export const sendFeedback = (payload: { comment: string; reason: string }) => {
    return {
        type: actionTypes.SEND_SUBSCRIPTION_FEEDBACK,
        payload,
    } as const;
};

export const updateSubscription = (payload: IUpdateSubscriptionData) => {
    return {
        type: actionTypes.UPDATE_SUBSCRIPTION,
        payload,
    } as const;
};

export const setCancellationCandidate = (payload: ISubscription | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const fetchDiscountSubscription = (payload: IFetchDiscountSubscription) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const setDiscountSubscription = (payload: IDiscountSubscription | null) => {
    return {
        type: actionTypes.SET_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const restoreSubscription = (payload: ISubscription) => {
    return {
        type: actionTypes.RESTORE_SUBSCRIPTION,
        payload,
    } as const;
};
