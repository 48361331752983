export const GET_CURRENT_USER_REQUEST = 'USER/GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'USER/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'USER/GET_CURRENT_USER_ERROR';

export const FORGOT_PASSWORD = 'USER/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'USER/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'USER/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'USER/FORGOT_PASSWORD_ERROR';

export const UPDATE_USER = 'USER/UPDATE_USER';
export const UPDATE_USER_REQUEST = 'USER/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USER/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'USER/UPDATE_USER_ERROR';

export const UPDATE_USER_EMAIL = 'USER/UPDATE_USER_EMAIL';

export const CHANGE_PASSWORD = 'USER/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUEST = 'USER/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'USER/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'USER/CHANGE_PASSWORD_ERROR';

export const RESET_USER_DATA = 'USER/RESET_USER_DATA';
