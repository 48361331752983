import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useCancelSubscriptionFlow } from 'hooks';

import { ExtendedModalTemplatePropsType } from 'components/Modals/Templates/FeedbackModalTemplate/types';

import { FeedbackModalTemplate } from '../../Templates';

const dataLocators = {
    title: 'unsubscribeErrorTitle',
    body: 'unsubscribeErrorBlock',
};

const ChangeSubscriptionErrorModal = ({ img, title, subtitle }: ExtendedModalTemplatePropsType) => {
    const { onChangeSubscription } = useCancelSubscriptionFlow();
    const { t } = useTranslation();

    return (
        <FeedbackModalTemplate
            dataLocators={dataLocators}
            img={img}
            title={title}
            subtitle={subtitle}
            contactUs={{ text: 'basics.contactSupport', subject: t('subscription.changeSubscription.error.title') }}
            withCloseButton
        >
            <Button
                dataLocator="unsubscribeErrorTryAgainButton"
                backgroundColor="primary-default"
                text={t('basics.tryAgain')}
                onClick={onChangeSubscription}
                mb={24}
            />
        </FeedbackModalTemplate>
    );
};

export default ChangeSubscriptionErrorModal;
