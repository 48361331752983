export const CANCEL_SUBSCRIPTION = 'SUBSCRIPTION/CANCEL_SUBSCRIPTION';
export const DISCARD_SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION/DISCARD_SUBSCRIPTION_CANCELLATION';
export const FETCH_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/FETCH_USER_SUBSCRIPTIONS';
export const SET_USER_SUBSCRIPTIONS = 'SUBSCRIPTION/SET_USER_SUBSCRIPTIONS';
export const RESET_SUBSCRIPTIONS = 'SUBSCRIPTION/RESET_SUBSCRIPTIONS';
export const SET_FETCHING_STATUS = 'SUBSCRIPTION/SET_FETCHING_STATUS';
export const SEND_SUBSCRIPTION_FEEDBACK = 'SUBSCRIPTION/SEND_SUBSCRIPTION_FEEDBACK';
export const UPDATE_SUBSCRIPTION = 'SUBSCRIPTION/UPDATE_SUBSCRIPTION';
export const SET_CANCELLATION_CANDIDATE = 'SUBSCRIPTION/SET_CANCELLATION_CANDIDATE';
export const FETCH_DISCOUNT_SUBSCRIPTION = 'SUBSCRIPTION/FETCH_DISCOUNT_SUBSCRIPTION';
export const SET_DISCOUNT_SUBSCRIPTION = 'SUBSCRIPTION/SET_DISCOUNT_SUBSCRIPTION';
export const RESTORE_SUBSCRIPTION = 'SUBSCRIPTION/RESTORE_SUBSCRIPTION';
