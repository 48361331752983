import { ComponentType, ComponentProps } from 'react';

import {
    WaitingModal,
    CancelSubscriptionErrorModal,
    CancellationReasonModal,
    ChangeSubscriptionErrorModal,
    DiscountModal,
    SuccessModal,
    WithLoaderModal,
    ConfirmationModal,
    NoConnectionModal,
    QuitWorkoutModal,
    CompletePersonalPlanModal,
    GoalModal,
    ProblemZonesModal,
    FitnessLevelModal,
    RestoreSubscriptionModal,
    RestoreSubscriptionErrorModal,
} from 'components/Modal';

export enum ModalName {
    DiscountModal = 'DiscountModal',
    CancellationReasonModal = 'CancellationReasonModal',
    ConfirmationModal = 'ConfirmationModal',
    WaitingModal = 'WaitingModal',
    WithLoaderModal = 'WithLoaderModal',
    SuccessModal = 'SuccessModal',
    ChangeSubscriptionErrorModal = 'ChangeSubscriptionErrorModal',
    CancelSubscriptionErrorModal = 'CancelSubscriptionErrorModal',
    NoConnectionModal = 'NoConnectionModal',
    QuitWorkoutModal = 'QuitWorkoutModal',
    CompletePersonalPlanModal = 'CompletePersonalPlanModal',
    GoalModal = 'GoalModal',
    ProblemZonesModal = 'ProblemZonesModal',
    FitnessLevelModal = 'FitnessLevelModal',
    RestoreSubscriptionModal = 'RestoreSubscriptionModal',
    RestoreSubscriptionErrorModal = 'RestoreSubscriptionErrorModal',
}

export type ModalPropsType = {
    [ModalName.DiscountModal]: ComponentProps<typeof DiscountModal>;
    [ModalName.CancellationReasonModal]: ComponentProps<typeof CancellationReasonModal>;
    [ModalName.ConfirmationModal]: ComponentProps<typeof ConfirmationModal>;
    [ModalName.WaitingModal]: ComponentProps<typeof WaitingModal>;
    [ModalName.WithLoaderModal]: ComponentProps<typeof WithLoaderModal>;
    [ModalName.SuccessModal]: ComponentProps<typeof SuccessModal>;
    [ModalName.ChangeSubscriptionErrorModal]: ComponentProps<typeof ChangeSubscriptionErrorModal>;
    [ModalName.CancelSubscriptionErrorModal]: ComponentProps<typeof CancelSubscriptionErrorModal>;
    [ModalName.NoConnectionModal]: ComponentType<typeof NoConnectionModal>;
    [ModalName.QuitWorkoutModal]: ComponentProps<typeof QuitWorkoutModal>;
    [ModalName.CompletePersonalPlanModal]: ComponentProps<typeof CompletePersonalPlanModal>;
    [ModalName.GoalModal]: ComponentProps<typeof GoalModal>;
    [ModalName.ProblemZonesModal]: ComponentProps<typeof ProblemZonesModal>;
    [ModalName.FitnessLevelModal]: ComponentProps<typeof FitnessLevelModal>;
    [ModalName.FitnessLevelModal]: ComponentProps<typeof FitnessLevelModal>;
    [ModalName.RestoreSubscriptionModal]: ComponentProps<typeof RestoreSubscriptionModal>;
    [ModalName.RestoreSubscriptionErrorModal]: ComponentProps<typeof RestoreSubscriptionErrorModal>;
};

export type ModalsMapType = {
    [key in ModalName]: ComponentType<ModalPropsType[key]>;
};
